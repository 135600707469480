import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../common';
import { CommonCrud } from '@/src/types';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { getUserStaffList, setSuspend, setUpdateUserStatus } from '../../http/user.v1';
import { apiPathV1, apiPathV2 } from '@/src/app.constants';
import useUserPagination, { IPaginationData, userPaginationDefault } from './hooks/useUserPagination';
import useUserModal, { IUserModal, userModalDefault } from './hooks/useUserModal';
import useUserSnackbar, { IUserSnackbarData, userSnackbarDefault } from './hooks/useUserSnackbar';
import { userRoleUpdate, userRoleDelete } from '../../http/role.v1';

declare namespace ActionTypes {
  export type SuspendUsers = CommonCrud<any>;
  export type UpdateUserStatus = CommonCrud<any>;
  export namespace RequestAct {
    export type SuspendUserAct = (params: any) => Promise<any>;
    export type UpdateSuspendAct = (params: { id: string; suspendValue: boolean }) => Promise<any>;
    export type UpdateUserStatusAct = (userId: string, activeStatus: boolean) => void;
    export type ChangeUserRole = (params: { userId: string; roleIds: string[] }) => Promise<any>;
    export type RemoveUserRole = (params: { userId: string; roleIds: string[]; isReloadUser: boolean }) => Promise<any>;
  }
}

interface IUserStoreContextV2 {
  suspends: string[];
  staffs: any[];
  loading: boolean;
  pagination: IPaginationData & { total: number };
  modal: IUserModal & { onConfirm: (id: string, checked: boolean) => void };
  fetchSuspendUsers: ActionUnit2<ActionTypes.SuspendUsers> & { requestAct: ActionTypes.RequestAct.SuspendUserAct };
  updateSuspend: ActionUnit2<CommonCrud<any>> & { requestAct: ActionTypes.RequestAct.UpdateSuspendAct };
  updateUserStatus: ActionUnit2<CommonCrud<any>> & { requestAct: ActionTypes.RequestAct.UpdateUserStatusAct };
  onSuspendToggle: (id: string, val: boolean) => void;
  snackbar: IUserSnackbarData;
  changeUserRole: ActionUnit2<CommonCrud<any>> & { requestAct: ActionTypes.RequestAct.ChangeUserRole };
  removeUserRole: ActionUnit2<CommonCrud<any>> & { requestAct: ActionTypes.RequestAct.RemoveUserRole };
}

const initialState: IUserStoreContextV2 = {
  suspends: [],
  staffs: [],
  loading: true,
  pagination: userPaginationDefault,
  // eslint-disable-next-line prettier/prettier
  modal: { ...userModalDefault, onConfirm: (_id: string, _checked: boolean) => {} },
  fetchSuspendUsers: { ...initialApiContext2<ActionTypes.SuspendUsers, ActionTypes.RequestAct.SuspendUserAct>() },
  updateSuspend: { ...initialApiContext2<CommonCrud<any>, ActionTypes.RequestAct.UpdateSuspendAct>() },
  updateUserStatus: { ...initialApiContext2<CommonCrud<any>, ActionTypes.RequestAct.UpdateUserStatusAct>() },
  changeUserRole: { ...initialApiContext2<CommonCrud<any>, ActionTypes.RequestAct.ChangeUserRole>() },
  removeUserRole: { ...initialApiContext2<CommonCrud<any>, ActionTypes.RequestAct.RemoveUserRole>() },
  // eslint-disable-next-line prettier/prettier
  onSuspendToggle: (_id: string, _val: boolean) => {},
  snackbar: userSnackbarDefault,
};

const UserStoreContext: React.Context<IUserStoreContextV2> = createContext(initialState);

const UserStoreContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [suspendUsers, setSuspendUsers] = useState<CommonCrud<any>>(initialState.fetchSuspendUsers.current);
  const [updateSuspendReq, setUpdateSuspendReq] = useState<CommonCrud<any>>(initialState.updateSuspend.current);
  const [updateUserStatusReq, setUpdateUserStatusReq] = useState<CommonCrud<any>>(initialState.updateUserStatus.current);
  const [userChangeRole, setUserChangeRoleReq] = useState<CommonCrud<any>>(initialState.changeUserRole.current);
  const [userRemoveRole, setUerRemoveRoleReq] = useState<CommonCrud<any>>(initialState.removeUserRole.current);
  const [suspends, setSuspends] = useState<string[]>([]);
  const userPagination = useUserPagination();
  const userModal = useUserModal();
  const userSnackbar = useUserSnackbar();

  const getUsers = useCallback<ActionTypes.RequestAct.SuspendUserAct>(
    async (params) => {
      setSuspendUsers({ ...initialState.fetchSuspendUsers.current, state: STATE_ENUM.LOADING });

      try {
        const { status, data, pagination } = await getUserStaffList(apiPathV2.users, params);
        const staffSuspends: string[] = data?.filter((s) => s.suspendDaysLeft > 0).map((s) => s.id) || [];
        setSuspends(staffSuspends);
        setSuspendUsers({ state: STATE_ENUM.READY, status, data });
        if (userPagination.setResponsePagination) {
          userPagination.setResponsePagination(pagination);
        }
      } catch (error) {
        commonSetError(error, setSuspendUsers);
      }
    },
    [userPagination.setResponsePagination]
  );

  const updateSuspend = useCallback<ActionTypes.RequestAct.UpdateSuspendAct>(
    async (params) => {
      setUpdateSuspendReq({ ...initialState.updateSuspend.current, state: STATE_ENUM.LOADING });

      const { status, data } = await setSuspend(apiPathV2.suspendUser, params.id, params.suspendValue);
      if (status && status?.code === 200) {
        setUpdateSuspendReq({ state: STATE_ENUM.READY, status, data });
        getUsers({
          page: userPagination.page,
          itemPerPage: userPagination.rowsPerPage,
          search: userPagination.search,
          orderBy: userPagination.orderBy,
          sort: userPagination.order,
        });
      } else {
        setUpdateSuspendReq({ state: STATE_ENUM.ERROR });
        throw new Error('error');
      }
    },
    [userPagination]
  );

  const onSuspendToggle = useCallback(
    (id: string, val: boolean) => {
      let newSuspend = [...suspends];
      const suspendedIndex = suspends.findIndex((s) => s === id);
      if (suspendedIndex >= 0) {
        newSuspend = newSuspend.filter((s) => s !== id);
      } else {
        newSuspend.push(id);
      }
      setSuspends(newSuspend);
    },
    [suspends, updateSuspend]
  );

  const onConfirmModal = useCallback(
    async (id: string, checked: boolean) => {
      if (id) {
        onSuspendToggle(id, checked);
        try {
          await updateSuspend({ id, suspendValue: checked });
          userSnackbar.handleSnackbar('success', 'update suspend success.');
          userSnackbar.setSnackbarOpen(true);
        } catch (error) {
          userSnackbar.handleSnackbar('error', 'Something went wrong. Please try again.');
          userSnackbar.setSnackbarOpen(true);
        }

        if (userModal?.setOpenModal) {
          userModal.setOpenModal(false);
        }
      }
    },
    [userModal.body.id, userPagination]
  );

  const updateUserStatus: ActionTypes.RequestAct.UpdateUserStatusAct = (userId, activeStatus) => {
    if (userId && activeStatus !== undefined) {
      const data: any = {
        activeStatus,
      };
      setUpdateUserStatusReq({ ...initialState.updateUserStatus.current, state: 'loading' });
      setUpdateUserStatus<ActionTypes.UpdateUserStatus>(apiPathV2.userStatus, userId, data)
        .then(({ status }) => {
          setUpdateUserStatusReq({ state: STATE_ENUM.READY, status });
          userSnackbar.handleSnackbar('success', activeStatus ? 'User successfully activate.' : 'User successfully deactivated.');
          userSnackbar.setSnackbarOpen(true);
          getUsers({
            page: userPagination.page,
            itemPerPage: userPagination.rowsPerPage,
            search: userPagination.search,
            orderBy: userPagination.orderBy,
            sort: userPagination.order,
          });
        })
        .catch((error) => {
          userSnackbar.handleSnackbar('error', 'Something went wrong. Please try again.');
          userSnackbar.setSnackbarOpen(true);
          commonSetError(error, setUpdateUserStatusReq);
        });
    }
  };

  const changeUserRole = useCallback<ActionTypes.RequestAct.ChangeUserRole>(
    async (params) => {
      try {
        setUserChangeRoleReq({ ...initialState.changeUserRole.current, state: STATE_ENUM.LOADING });
        const requests = params.roleIds.map((role) => {
          return userRoleUpdate(apiPathV1.role, params.userId, role);
        });
        const results = await Promise.all(requests);
        const response = results.find((t) => t.status?.code !== 200);

        if (response) {
          commonSetError(response.error, setUserChangeRoleReq);
        } else {
          setUserChangeRoleReq({ state: STATE_ENUM.READY, status: results[0].status.code, data: results[0].data });
          userSnackbar.handleSnackbar('success', 'update user role success.');
          userSnackbar.setSnackbarOpen(true);
          getUsers({
            page: userPagination.page,
            itemPerPage: userPagination.rowsPerPage,
            search: userPagination.search,
            orderBy: userPagination.orderBy,
            sort: userPagination.order,
          });
        }
      } catch (error) {
        commonSetError(error, setUserChangeRoleReq);
      }
    },
    [userPagination]
  );

  const removeUserRole = useCallback<ActionTypes.RequestAct.RemoveUserRole>(
    async (params) => {
      try {
        if (params.isReloadUser) {
          setUerRemoveRoleReq({ ...initialState.removeUserRole.current, state: STATE_ENUM.LOADING });
        }
        const requests = params.roleIds.map((role) => {
          return userRoleDelete(apiPathV1.role, params.userId, role);
        });
        const results = await Promise.all(requests);
        const response = results.find((t) => t.status?.code !== 200);

        if (response && params.isReloadUser) {
          commonSetError(response.error, setUerRemoveRoleReq);
        } else {
          setUerRemoveRoleReq({ state: STATE_ENUM.READY, status: results[0].status.code, data: results[0].data });
          if (params.isReloadUser) {
            userSnackbar.handleSnackbar('success', 'update user role success.');
            userSnackbar.setSnackbarOpen(true);
            getUsers({
              page: userPagination.page,
              itemPerPage: userPagination.rowsPerPage,
              search: userPagination.search,
              orderBy: userPagination.orderBy,
              sort: userPagination.order,
            });
          }
        }
      } catch (error) {
        if (params.isReloadUser) {
          commonSetError(error, setUserChangeRoleReq);
        }
      }
    },
    [userPagination]
  );

  useEffect(() => {
    getUsers({
      itemPerPage: userPagination.rowsPerPage,
      page: userPagination.page,
      search: userPagination.search,
      orderBy: userPagination.orderBy,
      sort: userPagination.order,
    });
  }, [userPagination.page, userPagination.rowsPerPage, userPagination.search, userPagination.orderBy, userPagination.order]);

  return (
    <UserStoreContext.Provider
      value={{
        suspends,
        staffs: suspendUsers?.data || [],
        loading: suspendUsers.state === STATE_ENUM.LOADING,
        fetchSuspendUsers: {
          current: suspendUsers,
          setState: setSuspendUsers,
          requestAct: getUsers,
        },
        updateSuspend: {
          current: updateSuspendReq,
          setState: setUpdateSuspendReq,
          requestAct: updateSuspend,
        },
        updateUserStatus: {
          current: updateUserStatusReq,
          setState: setUpdateUserStatusReq,
          requestAct: updateUserStatus,
        },
        pagination: {
          ...userPagination,
          total: userPagination.responsePagination?.total || 0,
        },
        modal: {
          ...userModal,
          onConfirm: onConfirmModal,
        },
        onSuspendToggle,
        snackbar: userSnackbar,
        changeUserRole: {
          current: userChangeRole,
          setState: setUserChangeRoleReq,
          requestAct: changeUserRole,
        },
        removeUserRole: {
          current: userRemoveRole,
          setState: setUerRemoveRoleReq,
          requestAct: removeUserRole,
        },
      }}
    >
      {children}
    </UserStoreContext.Provider>
  );
};

const withUserStoreContext = (Component: any) => {
  return function UserStoreComponent(props: any) {
    return <UserStoreContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</UserStoreContext.Consumer>;
  };
};

const useUserStoreContext = () => {
  return useContext(UserStoreContext);
};

export { UserStoreContextProvider, withUserStoreContext, useUserStoreContext };
