import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMenuContextV2, useSnackbarContext, useRestaurantContext, initialMenuState } from '@/services/index';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  TextField,
  Tooltip,
  Button,
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  InputLabel,
  Divider,
} from '@mui/material';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR, LOADING } from '@/app.constants/index';
import { QueryMenuListSortBy, order, MenuOverviewList, QueryParamsGetList } from '@/types/index';
import { PriceInput } from '../sharedPriceInputComponent';
import { ImageUpload } from '../imageUpload';
import { styles } from '@/src/styles/theme';
import { CurrentImageDataType } from '../tableBodyRestaurantMenu/index';
import { updateTablePaginationTotal } from '@/src/helpers/tableHelpers';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AddRestaurantMenuProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setOrderBy: Dispatch<SetStateAction<QueryMenuListSortBy | null>>;
  setOrder: Dispatch<SetStateAction<order | null>>;
  setPage: Dispatch<SetStateAction<number>>;
  setTotal: Dispatch<SetStateAction<number>>;
  total: number;
  dropdownAddOnsData: { label: string; value: string }[];
  dropdownOptionGroupsData: { label: string; value: string }[];
  page: number;
  rowsPerPage: number;
  menuPaginationParams: QueryParamsGetList<QueryMenuListSortBy>;
  setMenuPaginationParams: Dispatch<SetStateAction<QueryParamsGetList<QueryMenuListSortBy>>>;
}

export function AddRestaurantMenu(props: AddRestaurantMenuProps) {
  const {
    setOpen,
    setOrderBy,
    setOrder,
    setPage,
    setTotal,
    total,
    dropdownAddOnsData,
    dropdownOptionGroupsData,
    menuPaginationParams,
    setMenuPaginationParams,
    page,
    rowsPerPage,
  } = props;
  const [thNameErrorText, setThNameErrorText] = useState('');
  const [enNameErrorText, setEnNameErrorText] = useState('');
  const [priceErrorText, setPriceErrorText] = useState('');
  const [thaiText, setThaiText] = useState('');
  const [engText, setEngText] = useState('');
  const [priceText, setPrice] = useState('');
  const initImageData: CurrentImageDataType = {
    preUploadFile: [],
    imgErrorText: '',
    previousImage: {},
    isUpdateImage: false,
    imageBase64: '',
  };
  const [currentImageData, setCurrentImageData] = useState(initImageData);
  const [validate, setValidate] = useState(false);
  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const countPrice = useRef(0);
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;
  const focusTextField = useRef<HTMLInputElement | null>(null);
  const loopTextField = useRef<HTMLInputElement | null>(null);
  const { id } = useParams();

  const [selectedAddOns, setSelectedAddOns] = useState<string[]>([]);
  const [selectedOptionGroups, setSelectedOptionGroups] = useState<string[]>([]);

  const { setResponseStatus } = useSnackbarContext();
  const { menuList, menuCreate, menuUpdate, menuDelete, overBudgetOrdersByMenu, menuListForOrder } = useMenuContextV2();
  const { restaurantDropdownList } = useRestaurantContext();
  const [isClickAdd, setIsClickAdd] = useState(false);
  const [checkSaveLoad, setCheckSaveLoad] = useState(false);

  useEffect(() => {
    /** Check LOADING and EDITING when edit or deleting to disabled ADD button   */
    if (
      menuUpdate.current.state === STATE_ENUM.LOADING ||
      menuDelete.current.state === STATE_ENUM.LOADING ||
      menuUpdate.current.state === STATE_ENUM.EDITING ||
      overBudgetOrdersByMenu.current.state === STATE_ENUM.LOADING
    ) {
      setCheckSaveLoad(true);
    } else {
      setCheckSaveLoad(false);
    }
  }, [menuUpdate, menuDelete]);

  const clearState = () => {
    setThaiText('');
    setEngText('');
    setPrice('');
    setCurrentImageData(initImageData);
    setSelectedAddOns([]);
    setSelectedOptionGroups([]);
    setIsClickAdd(false);
  };
  const classes = styles();

  const handleSelectAddOns = (event: SelectChangeEvent<string[]>, child: any) => {
    event.preventDefault();
    if (child?.props?.value === 'all') {
      if (selectedAddOns.length === dropdownAddOnsData.length) {
        // case: un-select all
        setSelectedAddOns([]);
      } else {
        // case: select all
        setSelectedAddOns(dropdownAddOnsData.map((item) => item.value) as string[]);
      }
    } else {
      // case: select/un-select one
      setSelectedAddOns(event.target.value as string[]);
    }
  };

  const handleSelectOptionGroups = (event: SelectChangeEvent<string[]>) => {
    event.preventDefault();
    setSelectedOptionGroups(event.target.value as string[]);
  };

  const callCreateMenuItem = () => {
    const payload = {
      thName: thaiText,
      enName: engText,
      price: parseInt(priceText),
      addonId: selectedAddOns,
      groupId: selectedOptionGroups,
      image: currentImageData.imageBase64,
    };
    menuCreate.requestAct(id || '', payload);
  };

  const handleClickAdd = () => {
    setIsClickAdd(true);
    setValidate(false);
    if (page === 0) {
      callCreateMenuItem();
    } else {
      setMenuPaginationParams((prevState) => {
        return { ...prevState, page: 0, itemPerPage: rowsPerPage };
      });
    }
  };

  useEffect(() => {
    // due to when we add from other page more than 0 we want to load fisrt page and place the new item on the top of table
    if (menuList.current.state === STATE_ENUM.READY && isClickAdd) {
      callCreateMenuItem();
      setPage(0);
    } else if (menuList.current.state === STATE_ENUM.ERROR) {
      setIsClickAdd(false);
      setValidate(true);
    }
  }, [menuList.current]);

  useEffect(() => {
    if (menuCreate.current.state === STATE_ENUM.READY) {
      updateTablePaginationTotal(total + 1, setTotal, menuList);

      setResponseStatus({
        status: 'success',
        message: menuCreate.current?.status?.message ?? '',
      });

      // Fetch updated menu list after menu creation is completed
      menuListForOrder.requestAct(id || '');

      if (menuList.current.data && menuList.current.data?.menuAddonOverviewList) {
        const tempResult: MenuOverviewList = {
          thName: menuCreate.current.data?.thName || '',
          enName: menuCreate.current.data?.enName || '',
          fallbackMeal: menuCreate.current.data?.fallbackMeal ?? false,
          price: menuCreate.current.data?.price || 0,
          id: menuCreate.current.data?.id || '',
          addon: menuCreate.current.data?.addon || [],
          group: menuCreate.current.data?.group || [],
          image: menuCreate.current.data?.image,
        };

        // TODO: should not update storeState directly
        const tempArray: MenuOverviewList[] = menuList.current.data.menuAddonOverviewList;
        tempResult.image.thumbnailLink = currentImageData.preUploadFile[0]?.dataURL;
        tempArray.unshift(tempResult);
        if (total >= rowsPerPage) {
          tempArray.pop();
        }
      }

      clearState();

      menuCreate.setState(initialMenuState.menuCreate.current);
      setOpen(true);
      setOrderBy(menuPaginationParams.orderBy || null);
      setOrder(menuPaginationParams.sort || null);
      setPage(0);
      focusTextField.current?.focus();
      countThaiText.current = 0;
      countEngText.current = 0;
      countPrice.current = 0;
    } else if (menuCreate.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: menuCreate.current.state,
        message: menuCreate.current?.status?.message ?? 'Something went wrong. Please try again.',
      });
      menuCreate.setState(initialMenuState.menuCreate.current);
      setOpen(true);
      setIsClickAdd(false);
    }
  }, [menuCreate.current]);

  const handleThaiNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    countThaiText.current = event.target.value.trim().length;
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };

  const handleEngNameTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    countEngText.current = event.target.value.trim().length;
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
      setThNameErrorText('');
    } else if (!thaiText.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
    } else if (countThaiText.current > 100) {
      setThNameErrorText('Max 100 characters.');
    } else {
      setThNameErrorText('');
    }
  };

  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
      setEnNameErrorText('');
    } else if (!engText.match(engChar)) {
      setEnNameErrorText('English name is required.');
    } else if (countEngText.current > 100) {
      setEnNameErrorText('Max 100 characters.');
    } else {
      setEnNameErrorText('');
    }
  };

  useEffect(() => {
    if (
      countEngText.current !== 0 &&
      countThaiText.current !== 0 &&
      countPrice.current !== 0 &&
      thNameErrorText === '' &&
      enNameErrorText === '' &&
      priceErrorText === ''
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, priceText]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        mb: 1,
        '.MuiFormControl-root': { mr: 2, mb: 3, p: 0 },
        '> .MuiFormControl-root': { width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          width: '100%',
        }}
      >
        <TextField
          value={thaiText}
          id="thaiName"
          label="Thai name"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={thNameErrorText !== ''}
          helperText={thNameErrorText}
          onChange={handleThaiNameTextChange}
          onBlur={handleOutOfFocusThaiName}
          tabIndex={-1}
          inputRef={focusTextField}
          ref={loopTextField}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <TextField
          value={engText}
          id="engName"
          label="English name"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '416px',
          }}
          error={enNameErrorText !== ''}
          helperText={enNameErrorText}
          onChange={handleEngNameTextChange}
          onBlur={handleOutOfFocusEngName}
          disabled={isClickAdd || checkSaveLoad}
          inputProps={{ maxLength: 101 }}
        />
        <PriceInput
          price={priceText}
          priceErrorText={priceErrorText}
          setPrice={setPrice}
          setPriceErrorText={setPriceErrorText}
          setValidate={setValidate}
          countPrice={countPrice}
          disabled={isClickAdd || checkSaveLoad}
        />
        <FormControl sx={{ width: '266px' }} size="small">
          <InputLabel htmlFor="addons-dropdown-label">Add-ons</InputLabel>
          <Select
            labelId="addons-dropdown-label"
            id="addons-dropdown"
            multiple
            displayEmpty
            value={selectedAddOns}
            variant="outlined"
            onChange={handleSelectAddOns}
            input={<OutlinedInput label="Add-ons" id="addon-input" />}
            renderValue={(selected) => {
              return selected
                .reduce((sum: string[], each: string) => {
                  const eachLabel = dropdownAddOnsData.find((item) => item.value === each);
                  return eachLabel ? [...sum, eachLabel.label] : sum;
                }, [])
                .join(', ');
            }}
            MenuProps={MenuProps}
            color="secondary"
            IconComponent={KeyboardArrowDownIcon}
            disabled={isClickAdd || checkSaveLoad}
          >
            {restaurantDropdownList.state !== 'ready' ? (
              <MenuItem key="" value="">
                <ListItemText primary={LOADING} />
              </MenuItem>
            ) : (
              dropdownAddOnsData.length === 0 && (
                <MenuItem key="no-items-found" value="" disabled>
                  <ListItemText primary="No items found" />
                </MenuItem>
              )
            )}

            {dropdownAddOnsData.length > 0 && restaurantDropdownList.state === 'ready' && (
              <MenuItem value="all" sx={{ pl: 0 }}>
                <ListItemIcon>
                  <Checkbox
                    id="select-all-addon"
                    checked={selectedAddOns.length === dropdownAddOnsData.length}
                    indeterminate={selectedAddOns.length > 0 && selectedAddOns.length < dropdownAddOnsData.length}
                  />
                </ListItemIcon>
                <ListItemText primary="All add-ons" />
              </MenuItem>
            )}
            {dropdownAddOnsData.length > 0 &&
              restaurantDropdownList.state === 'ready' &&
              dropdownAddOnsData.map((item) => (
                <MenuItem key={item.value} value={item.value} sx={{ pl: 3 }} className={classes.wrap_text}>
                  <ListItemIcon>
                    <Checkbox checked={selectedAddOns.includes(item.value)} />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '266px' }} size="small">
          <InputLabel htmlFor="option-group-dropdown-label">Option groups</InputLabel>
          <Select
            labelId="option-group-dropdown-label"
            id="option-group-dropdown"
            multiple
            value={selectedOptionGroups}
            variant="outlined"
            onChange={handleSelectOptionGroups}
            input={<OutlinedInput label="Option groups" />}
            renderValue={(selected) => {
              return selected
                .reduce((sum: string[], each: string) => {
                  const eachLabel = dropdownOptionGroupsData.find((item) => item.value === each);
                  return eachLabel ? [...sum, eachLabel.label] : sum;
                }, [])
                .join(', ');
            }}
            MenuProps={MenuProps}
            color="secondary"
            IconComponent={KeyboardArrowDownIcon}
            disabled={isClickAdd || checkSaveLoad}
          >
            {restaurantDropdownList.state !== 'ready' ? (
              <MenuItem key="" value="">
                <ListItemText primary={LOADING} />
              </MenuItem>
            ) : dropdownOptionGroupsData.length === 0 ? (
              <MenuItem key="no-items-found" value="" disabled>
                <ListItemText primary="No items found" />
              </MenuItem>
            ) : (
              dropdownOptionGroupsData.map((item) => (
                <MenuItem key={item.value} value={item.value} sx={{ pl: 1 }} className={classes.wrap_text}>
                  <ListItemIcon>
                    <Checkbox checked={selectedOptionGroups.includes(item.value)} />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <ImageUpload
            id={`create-menu`}
            currentImageData={currentImageData}
            setCurrentImageData={setCurrentImageData}
            layout={{ width: 40, height: 40 }}
            useBuiltInCallError
            disabled={isClickAdd || checkSaveLoad}
          />
          <Divider orientation="vertical" sx={{ mx: '10px', mb: '15%', borderRightWidth: 2 }} flexItem />
        </Box>
        <Tooltip title="Add menu item" placement="bottom-start">
          <Button
            id="btn-add-restaurant-menu"
            variant="contained"
            component="span"
            color="primary"
            onClick={handleClickAdd}
            disabled={!validate || checkSaveLoad}
            onKeyDown={(e: any) => {
              if (e.key === 'Tab') {
                loopTextField.current?.focus();
              }
            }}
          >
            Add
          </Button>
        </Tooltip>
      </FormControl>
    </Box>
  );
}
