/**
 * Axios tutorials
 * source: https://axios-http.com/docs/intro
 *
 * @DOES
 * - For each new API please create it from axios, all get and post methods should be created from http.service
 *
 * @DONTS
 * - Dont create independent axios nested in application
 */

import { ENVS } from '@/app.constants/index';
import axios, { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import Log from 'color-logger';
import { Cookies } from 'react-cookie';

// REACT_APP_API_PREFIX=/api

// ## location of hosted application
// REACT_APP_BASE_URL=http://localhost:3000

/** Base class for Axios */
export class AxiosService {
  debug: boolean;
  private _client: any;
  private _config: AxiosRequestConfig;

  // new
  /** this is only available on localhost with firebase in localhost development */
  private apiPrefix = ENVS.REACT_APP_API_TEST_API === '1' ? ENVS.BASE_URL + ENVS.API_PREFIX : ENVS.API_PREFIX;
  // REACT_APP_BASE_URL
  private baseURL: string = '';
  /** disable catch rejections promises */
  private reject: boolean;
  constructor(config: AxiosRequestConfig, debug = true, reject: boolean = true) {
    if (!config) {
      console.error('[AxiosService][error]', 'AxiosRequestConfig is empty');
    }

    this._config = config;
    this.extends();
    this._client = axios;
    this.debug = debug;
    this.reject = reject;
  }

  extends() {
    // const cookies = new Cookies();
    // const jwtToken = cookies.get('jwtToken');
    this.baseURL = new URL(this.apiPrefix, ENVS.API_BASE).toString();
    const config: AxiosRequestConfig = {
      baseURL: this.baseURL,
      responseType: 'json',
      ...(ENVS.ENVIRONMENT === 'LOCAL' ? { headers: { 'Access-Control-Allow-Origin': '*' } } : {}),
      timeout: 20000,
      transformRequest: [
        function (data) {
          return data;
        },
      ],
    };

    this._config = Object.assign({}, config, this._config);
  }

  /** TODO
   * Implement correct error code messages from ./Codes
   */
  defaultHandleError = (error: AxiosError): Promise<AxiosError<any>> | AxiosError<any> => {
    // REVIEW PICKING favicon.ico thru this request
    // >> must be issue with server api or configuration
    if (!axios.isAxiosError(error)) {
      Log.e('[NOnAxiosService][error]: >');
      return Promise.reject(new Error(error));
    }
    if (!axios.isCancel(error)) {
      Log.e('[NOnAxiosService][isCancel]: >');
      return Promise.reject(new Error(error));
    }

    if (error.response && this.debug) {
      Log.e('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    }
    if (!error.response && !this.debug) {
      Log.e('[AxiosService][error]:', error);
    }
    const executeUserSessionExpired = (code: number) => {
      // eslint-disable-next-line no-case-declarations
      const event = new CustomEvent('EVENT_USER_SESSION_EXPIRED', {
        bubbles: true,
        detail: {
          code,
          message: code === 401 ? 'Your session expired' : code === 406 ? 'Account disabled, or no privileges' : 'No access',
        },
      });

      document.dispatchEvent(event);
    };
    // REVIEW PICKING favicon.ico thru this request
    // >> must be issue with server api or configuration
    if (!axios.isAxiosError(error)) {
      Log.e('[NOnAxiosService][error]: >');
      return Promise.reject(new Error(error));
    }
    if (!axios.isCancel(error)) {
      console.log('[AxiosService][406]', 'isCancel');
      executeUserSessionExpired(406);
    }

    if (error.response && this.debug) {
      Log.e('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    }
    if (!error.response && !this.debug) {
      Log.e('[AxiosService][error]:', error);
    }

    switch (error.response?.status) {
      case 400:
        console.log('[AxiosService][400]', error.message);
        break;
      case 401:
        // TODO add dispatch event to handle global 401 errors from one place and provide modal/popup
        console.log('[AxiosService][401]', error.message);
        console.error('[AxiosService][user_session_invalid]', error.message);
        executeUserSessionExpired(401);

        break;
      case 404:
        console.log('[AxiosService][404]', error.message);
        break;
      case 406:
        // account not found, disabled, or no privileges
        console.log('[AxiosService][406]', error.message);
        executeUserSessionExpired(406);
        break;

      case 500:
        console.log('[AxiosService][500]', error.message);
        break;
      default:
        console.log(`[AxiosService][${error.response?.status}][unhandled]`, error?.response?.statusText);
        break;
    }
    if (this.reject) return Promise.reject(error);
    else {
      Log.w('[AxiosService][soft][error]');
      // NOTE when soft error is enabled it will return response from .then, and not catch it again, just so you know
      return null as any;
    }
  };

  /**
   *
   * @description refer to https://axios-http.com/docs/res_schema
   */
  response(resp: AxiosResponse) {
    return resp;
  }

  get config(): any {
    const cookies = new Cookies();
    const jwtToken = cookies.get('jwtToken');
    const config = {
      ...(ENVS.ENVIRONMENT === 'LOCAL' ? { 'Access-Control-Allow-Origin': '*' } : {}),
      headers: {
        'Content-Type': 'application/json',
        ...(jwtToken ? { Authorization: 'Bearer ' + jwtToken } : {}),
      },
    };
    return config;
  }

  /** creates new clean {AxiosInstance} for every request  */
  get client(): AxiosInstance {
    return this._client?.create(this._config) as AxiosInstance;
  }
}
