import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export default function ModalForChangeUserRole({ open, handleClose, handleConfirm, user, roles }) {
  const [checkedItems, setCheckedItems] = useState<any[]>([]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      const items = [...checkedItems];
      items.push({ id: name, checked });
      setCheckedItems(items);
    } else {
      const items = checkedItems.filter((item: any) => item.id !== name);
      setCheckedItems(items);
    }
  };

  useEffect(() => {
    if (user) {
      const items: [] = user.roles.map((role) => {
        return { id: role.id, checked: true };
      });
      setCheckedItems(items || []);
    }
  }, [user, user?.roles]);

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="fallbackMeal" aria-describedby="modal-fallbackMeal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '5px',
          }}
        >
          <Typography
            sx={{ color: '#000', fontSize: '20px', width: 'inherit', fontWeight: 'bold', marginBottom: '13px' }}
            id="transition-modal-title"
            variant="h5"
            component="h2"
          >
            Change Role For: {user?.displayName || ''}
          </Typography>
          <>
            {roles?.map((item, index) => (
              <FormGroup key={'select-role-frm-' + index} className="editInputNoPointer">
                <FormControlLabel
                  key={'select-role-' + item.id}
                  control={<Checkbox id={'check-' + item.id} checked={checkedItems.find((t) => t.id === item.id)?.checked || false || item.code === 'USER'} />}
                  label={item.name}
                  name={item.id}
                  disabled={item.code === 'USER'}
                  onChange={handleCheckboxChange}
                />
              </FormGroup>
            ))}
          </>
          <Button
            onClick={() => {
              handleConfirm([...checkedItems]);
              setCheckedItems([]);
            }}
            variant="contained"
            sx={{ mt: 3, mr: 1 }}
          >
            CONFIRM
          </Button>
          <Button
            onClick={() => {
              setCheckedItems([]);
              handleClose();
            }}
            variant="outlined"
            sx={{ mt: 3 }}
          >
            CANCEL
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
