import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button, Divider, Stack, Alert, AutocompleteChangeReason } from '@mui/material';
import { AddonAutocomplete, MenuItemAutocomplete, GroupAutocomplete } from '../autocomplete';
import { useMenuContextV2, useOrdersContextV2, useSnackbarContext } from '@/services/index';
import { RestaurantAddOnsSchema, RestaurantMenuSchema, MenuList, GroupWithOptionAddon, Addon, RestaurantOptionAddonSchema } from '@/types/index';
import { DEFAULT_ROW_PER_PAGE, MAX_PURCHASE_LIMIT } from '@/src/app.constants';
import { updateFallbackMealSchema } from '@/src/types/schema/updateFallbackMeal';

const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '60px',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface ModalSetFallbackMealsProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  openSnackbar: Dispatch<SetStateAction<boolean>>;
  restaurantId: string | undefined;
  isUpdateMode: boolean;
  menuId: string;
}

export default function ModalSetFallbackMeals(props: ModalSetFallbackMealsProps) {
  const { setOpenModal, openModal, openSnackbar, restaurantId, isUpdateMode, menuId } = props;
  // context consumers
  const { orderCreate } = useOrdersContextV2();
  const { menuListForOrder, fallbackMealChange } = useMenuContextV2();
  const { setResponseStatus } = useSnackbarContext();
  // user input states
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(menuId);
  const [selectedAddons, setSelectedAddons] = useState<RestaurantAddOnsSchema[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<RestaurantOptionAddonSchema[]>([]);

  // list states
  const [groupsList, setGroupsList] = useState<GroupWithOptionAddon[]>([]);
  const [addonsList, setAddonsList] = useState<Addon[]>([]);
  // price states
  const [menuItemPrice, setMenuItemPrice] = useState<number>(0);
  const [addOnsPrice, setAddOnsPrice] = useState<number>(0);
  const [optionsPrice, setOptionsPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [defaultGroupWithOptions, setDefaultGroupWithOptions] = useState<GroupWithOptionAddon[] | null>(null);
  const [defaultMenuValue, setDefaultMenuValue] = useState<MenuList | null>(null);
  const [isCanSave, setIsCanSave] = useState(true);

  const { menuList } = useMenuContextV2();

  const clearInputStates = () => {
    setMenuItemPrice(0);
    setAddOnsPrice(0);
    setOptionsPrice(0);
    setAddonsList([]);
    setSelectedAddons([]);
    setSelectedOptions([]);
    setIsCanSave(true);
  };

  /**
   * EFFECTS
   */

  // watch for successful Save
  useEffect(() => {
    if (orderCreate.current.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: orderCreate.current.status?.message ?? '',
      });
      clearInputStates();
      orderCreate.setState({
        state: 'initial',
        data: undefined,
      });
      openSnackbar(true);
    } else if (orderCreate.current.state === 'error') {
      setResponseStatus({
        status: 'error',
        message: orderCreate.current.status?.message ?? '',
      });
      orderCreate.setState({
        state: 'initial',
        data: undefined,
      });
    }
  }, [orderCreate.current]);

  // watch price changes and calculate total
  useEffect(() => {
    // check request option
    const selectedIds = new Set(selectedOptions.map((option) => option.id));
    const radioGroups = groupsList.filter((group) => group.groupRadio);
    const isMatchingPickOne = radioGroups.every((group) => {
      const hasMatchingAddon = group.addon.some((addon) => selectedIds.has(addon.id));

      return hasMatchingAddon;
    });

    const sum = menuItemPrice + addOnsPrice + optionsPrice;
    setIsCanSave(isMatchingPickOne && sum <= MAX_PURCHASE_LIMIT);
    setTotalPrice(sum);
  }, [addOnsPrice, optionsPrice, selectedOptions]);

  /**
   * HANDLERS
   */

  const handleCloseModal = () => {
    clearInputStates();
    setOpenModal(false);
  };

  const handleSave = () => {
    const combinedAddons: any[] = [];
    selectedAddons.forEach((addon) => {
      combinedAddons.push({
        id: addon.id,
        type: 'addon',
        groupId: '',
      });
    });

    selectedOptions.forEach((optionAddon) => {
      combinedAddons.push({
        id: optionAddon.id,
        type: 'optionAddon',
        groupId: groupsList.find((i) => i.addon.find((j) => j.id === optionAddon.id))?.id,
      });
    });

    const payload: updateFallbackMealSchema = {
      menuId: selectedMenuItem,
      addonId: combinedAddons,
      restaurantId: restaurantId!,
    };
    fallbackMealChange.requestAct(payload);
  };

  const handleMenuItemSelect = (event: React.SyntheticEvent, value: RestaurantMenuSchema, reason: string) => {
    // clear prices and selected lists ONLY
    setMenuItemPrice(0);
    setAddOnsPrice(0);
    setOptionsPrice(0);
    setSelectedAddons([]);
    setSelectedOptions([]);
    setIsCanSave(true);

    if (reason !== 'clear') {
      const menuListWithAddOns = menuListForOrder.current.data?.menuAddonList;
      // filter data based on selected menuId
      const foundGroups: MenuList = menuListWithAddOns?.find((menuItem) => menuItem.id === value.id);
      const foundAddons: MenuList = menuListWithAddOns?.find((menuItem) => menuItem.id === value.id);

      const defaultAddonIds = menuListForOrder.current.data?.defaultDetail?.addon?.map((addon: any) => addon.id);
      if (foundGroups.group) {
        const defaultGroupWithOptions: GroupWithOptionAddon[] = foundGroups.group.map((group: any) => ({
          ...group,
          addon: defaultAddonIds?.length ? group.addon.filter((addon: any) => defaultAddonIds.includes(addon.id)) : group.addon,
        }));

        setGroupsList(foundGroups.group);

        setDefaultGroupWithOptions(defaultGroupWithOptions);
      }

      if (foundAddons.addon) {
        setAddonsList(foundAddons.addon);
      }

      if (isUpdateMode) {
        const matchingGroups = foundGroups.group
          .flatMap((group) => group.addon) // ดึง addon จาก ทุก group
          .filter((addon) => defaultAddonIds.includes(addon.id));
        const matchingAddons = foundAddons.addon.filter((addon) => defaultAddonIds.includes(addon.id));

        setOptionsPrice(matchingGroups.reduce((a, b) => a + b.price, 0));
        setSelectedOptions(matchingGroups);

        setAddOnsPrice(matchingAddons.reduce((a, b) => a + b.price, 0));
        setSelectedAddons(matchingAddons);
      }

      setMenuItemPrice(value.price);

      if (foundGroups.group.some((i) => i.groupRadio) && !isUpdateMode) {
        setIsCanSave(false);
      }
    }
  };

  const handleSelectedAddOns = (addons: RestaurantAddOnsSchema[], reason: string) => {
    // create 1D arrays for ids and prices
    const addonPriceArray = addons.map((addon) => addon.price);
    // calculate total price
    const addonPriceTotal = addonPriceArray.reduce((p, a) => p + a, 0);
    setSelectedAddons(addons);
    setAddOnsPrice(addonPriceTotal);
  };

  const handleSelectGroupOptions = (event: any, value: any, reason: AutocompleteChangeReason, details: any, multiple: boolean, remove?: any) => {
    let selected: any;
    let tempSelectedOptions = selectedOptions;
    if (multiple && reason !== 'clear') {
      selected = details.option;
    } else {
      selected = value;
    }

    if (remove) {
      const updatedOptions = selectedOptions.filter((option) => {
        return option.id !== remove.id;
      });

      tempSelectedOptions = updatedOptions;
    }
    if (reason === 'selectOption') {
      const updatedOptions = [...tempSelectedOptions];
      updatedOptions.push(selected);
      const updatedPrice = updatedOptions.reduce((a, b) => a + b.price, 0);

      setSelectedOptions(updatedOptions);
      setOptionsPrice(updatedPrice);
    } else if (reason === 'removeOption') {
      const updatedOptions = tempSelectedOptions.filter((option) => {
        return option.id !== selected.id;
      });

      const updatedPrice = updatedOptions.reduce((a, b) => a + b.price, 0);
      setSelectedOptions(updatedOptions);
      setOptionsPrice(updatedPrice);
    } else if (reason === 'clear') {
      setSelectedOptions([]);
      setOptionsPrice(0);
    }
  };

  useEffect(() => {
    if (menuId) {
      setDefaultMenuValue(menuListForOrder.current.data?.menuAddonList.find((option) => option.id === menuId));
      setSelectedMenuItem(menuId);
    }
  }, [menuId]);

  useEffect(() => {
    if (fallbackMealChange.current.state === 'ready') {
      menuList.requestAct(restaurantId!, {
        page: 0,
        itemPerPage: DEFAULT_ROW_PER_PAGE,
        orderBy: 'thName',
        sort: 'asc',
      });
      menuListForOrder.requestAct(restaurantId || '');
    }
  }, [fallbackMealChange.current.state]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Fade in={openModal} style={{ maxHeight: '80%', overflowY: 'auto' }}>
        <Box sx={modalStyle}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            Set Fallback meals
          </Typography>
          <Divider />
          <Typography id="transition-modal-description" sx={{ mt: 2, mb: 2 }} style={{ color: '#212121' }}>
            Note: Total amount must not exceed {MAX_PURCHASE_LIMIT} Baht
          </Typography>

          {/* Name Input + Menu Item Select */}
          <Stack spacing={4}>
            <MenuItemAutocomplete callback={handleMenuItemSelect} defaultMenuValue={defaultMenuValue} isReadonly={true} />
            {/* Display Addons if: Menu Item has been selected  (assumes they exist, may need to update this) */}
            {selectedMenuItem !== '' ? (
              <>
                {addonsList.length > 0 ? (
                  <AddonAutocomplete
                    clearId="clear-add-on"
                    sx={{ pl: '21px' }}
                    addonsList={addonsList}
                    selectedAddons={selectedAddons}
                    callback={handleSelectedAddOns}
                  />
                ) : (
                  <></>
                )}
                {groupsList.length > 0 ? (
                  groupsList.map((group, index) => (
                    <GroupAutocomplete
                      clearIconId="clear-option"
                      key={group.id}
                      id={group.id}
                      sx={{ pl: '21px' }}
                      name={group.enName}
                      multiple={!group.groupRadio}
                      options={group.addon}
                      callback={handleSelectGroupOptions}
                      order={index}
                      defaultValue={isUpdateMode ? defaultGroupWithOptions?.find((i) => i.id === group.id)?.addon : null}
                      // onLabelValueChange={handleLabelValueChange}
                    />
                  ))
                ) : (
                  <></>
                )}
                <Typography
                  id="price-text"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mr: 2,
                    mt: 4,
                    fontSize: 18,
                    color: totalPrice <= MAX_PURCHASE_LIMIT ? '#212121' : '#F44336',
                  }}
                >
                  Total price: {totalPrice} Baht
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Stack>
          {totalPrice > MAX_PURCHASE_LIMIT ? (
            <Alert severity={'error'} id="error-budget-limit">
              The order exceeds the budget limit.
            </Alert>
          ) : (
            <></>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px' }}>
            <Button id={'btn-modal-create-order-add'} disabled={!isCanSave} color={'primary'} variant={'contained'} onClick={handleSave}>
              {isUpdateMode ? 'UPDATE' : 'SET'}
            </Button>
            <Button id={'btn-modal-create-order-cancel'} color={'success'} variant={'outlined'} sx={{ ml: 2 }} onClick={handleCloseModal}>
              CANCEL
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
